var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-data-table',{attrs:{"elevation":"10","dense":"","headers":_vm.headers,"items":_vm.assets,"item-key":"ID","footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus',
      itemsPerPageText: 'Beni per pagina',
      itemsPerPageAllText: 'Tutti i beni',
      itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
      pageText: '',
    }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-sheet',[_vm._v(" Nessun bene ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-sheet',[_vm._v(" Nessun bene ")])]},proxy:true},{key:"item.ID",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.ID)+" ")])]}},{key:"item.DESC",fn:function({ item }){return [(item.MARCA)?_c('span',[_vm._v(_vm._s(item.MARCA)+" ")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.DS_BENE)+" ")])]}},{key:"item.icons",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
              _vm.$store.state.userRole === 'admin' ||
              _vm.$store.state.userRole === 'operator' ||
              _vm.$store.state.userRole === 'auditor'
            )?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":'/assets/' + item.ID}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Visualizza")])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }